import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FactoryGetAllContract,
  FactoryGetIndexValue,
} from "../../Helpers/FactoryHelper";
import {
  StackingHelperContract,
  StackingPoolstakeCounter,
  StackingPoolstakersDataset,
  StackingPoolUnstake,
} from "../../Helpers/StackingHelper";
import { connect } from "react-redux";
import { store } from "../../Redux/store";
import Swal from "sweetalert2";
import { UpdateConnectLoading } from "../../Redux/Action";
import { PayoutTokenABI } from "../../Config/ABI/PayoutTokenABI";
import {
  PayoutTokenContract,
  StakingContract,
} from "../../Config/Contract/Contract";
import { web3_ } from "../../Services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UnstackICOModal(props) {
  const [open, setOpen] = React.useState(true);
  const [Counter, SetCounter] = React.useState(false);
  const [stackCounter, setStackCounter] = React.useState([]);
  const handleOpen = () => props.setisOpen(true);
  const handleClose = () => props.setisOpen(false);
  useEffect(async () => {
    let counter, ArrayAddress;
    console.log(props._isConnect, "dhasfhgdhsfjg");
    if (props._isConnect && props.address) {
      await FactoryGetAllContract().then(async (res) => {
        ArrayAddress = res;
        let data = await FactoryGetIndexValue(ArrayAddress, props.ICOaddress);
        counter = parseInt(data[1]) + 1;
        console.log(counter);
        SetCounter(counter);
      });
      console.log(counter, ArrayAddress);
      await StackingPoolstakeCounter(counter, props.address).then(
        async (res) => {
          let Array = [];
          for (let index = 1; index <= res; index++) {
            Array.push(index);
          }
          setStackCounter(Array);
          console.log(Array);
        }
      );
    }
  }, [props._isConnect, props.address]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unstake Ammount
          </Typography>
          {props.modalOpen ? (
            <>
              {" "}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {stackCounter.map((index, key) => (
                  <>
                    <div
                      key={key}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <strong>Unstake {index} :</strong>
                      </div>
                      <div
                        onClick={async () => {
                          let stackAllowence = await new web3_.eth.Contract(
                            PayoutTokenABI,
                            PayoutTokenContract
                          ).methods
                            .balanceOf(StakingContract)
                            .call();

                          console.log(stackAllowence / Math.pow(10, 18));
                          props.setisOpen(false);
                          StackingPoolstakersDataset(
                            Counter,
                            parseInt(index),
                            store.getState().ConnectWallet.address
                          ).then((res) => {
                            if (res.stackAmount <= 0) {
                              props.setisOpen(false);
                              Swal.fire({
                                icon: "warning",
                                text: "You have already staked",
                              });
                            } else {
                              console.log(
                                (parseFloat(res.stackAmount) +
                                  parseFloat(res.apy)) /
                                  Math.pow(10, 18),
                                parseFloat(stackAllowence) / Math.pow(10, 18)
                              );
                              if (
                                (parseFloat(res.stackAmount) +
                                  parseFloat(res.apy)) /
                                  Math.pow(10, 18) <=
                                parseFloat(stackAllowence) / Math.pow(10, 18)
                              ) {
                                StackingPoolUnstake(Counter, index);
                              } else {
                                Swal.fire({
                                  icon: "warning",
                                  text: "Staked Contract does't have enough balance",
                                });
                              }
                            }
                          });
                        }}
                        className="py-[8px] px-[16px] bg-[#060b27] text-white  rounded mb-5 cursor-pointer "
                      >
                        Click me{" "}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  _isConnect: state.ConnectWallet.isConnect,
});
export default connect(mapStateToProps, null)(UnstackICOModal);
