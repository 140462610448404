import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PrivateRoute from "./PrivateRoute";
import StackPage from "../pages/StackPage";
import Loading from "../Components/Loading/Loading";
import IDOPage from "../pages/IDOPage";
import CreatePage from "../pages/CreatePage";
function RouteList() {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route index path="/" element={<HomePage />} />
        <Route path="/stack/:address" element={<StackPage />} />
        <Route path="/" element={<StackPage />} />
        {/* <Route path="/create" element={<CreatePage />} /> */}
        <Route path="/ido/:address" element={<IDOPage />} />
        <Route path="/load" element={<Loading />} />
      </Route>
    </Routes>
  );
}

export default RouteList;
