import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import RouteList from "./Routes/RouteList";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import { isWalleConnect } from "./Redux/Action";
import { ConnectMetamask } from "./Services";
function App() {
  if (
    localStorage.getItem("WalletConnect") !== null &&
    JSON.parse(localStorage.getItem("WalletConnect")).isConnect === true
  ) {
    let data = JSON.parse(localStorage.getItem("WalletConnect"));
    console.log(data);
    isWalleConnect(data.isConnect, data.address);
    ConnectMetamask();
  }
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <RouteList />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
