import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoCloseCircleSharp, IoWallet, IoExitOutline } from "react-icons/io5";
import { connect, useDispatch } from "react-redux";
import { UpdateConnectLoading, UpdateWalletBalance } from "../Redux/Action";
import {
  ConnectMetamask,
  ConnectWeb3Wallet,
  DisconnectWallet,
} from "../Services";
import { GiHamburgerMenu } from "react-icons/gi";
import { spliceString } from "../Helpers/utils";
import { store } from "../Redux/store";
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  _isConnectModalOpen: state.Modal.isConnectModalOpen,
  _isConnect: state.ConnectWallet.isConnect,
});
function Header(props) {
  const [isConnect, setisConnect] = useState(false);
  const [isConnetModalOpen, setisConnetModalOpen] = useState(false);
  const [isBurger, setisBurger] = useState(false);
  useEffect(() => {
    setisConnetModalOpen(props._isConnectModalOpen);
  }, [props._isConnectModalOpen]);

  useEffect(() => {
    setisConnect(props._isConnect);
  }, [props._isConnect]);

  function handleBurger() {
    setisBurger(!isBurger);
  }

  function handleCloseConnectModal() {
    setisConnetModalOpen(false);
  }
  // For Open Connect Modal
  function handleOpenConnectModal() {
    setisConnetModalOpen(true);
  }

  function handleWalletDisconnect() {
    setisConnect(false);
    DisconnectWallet();
    // store.getState().ConnectWallet.address = "";
  }
  return (
    <>
      {" "}
      <div className=" bg-black z-[1000] px-[40px] py-[20px] flex border-b-2 border-[#d9b865] justify-between items-center shadow-sm top-0  sticky">
        <Link to="/" className="flex gap-2 items-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            className="rounded"
            width="120px"
            alt=""
          ></img>
        </Link>
        <div className="hidden md:flex text-[#060b27] font-semibold">
          <Link
            to="/"
            className=" text-white  rounded-lg cursor-pointer transition duration-250 ease-out hover:ease-in"
          >
            <div className="px-[16px] py-[8px] ">Dashboard</div>
          </Link>
          <a
            href="https://forms.gle/JsPAaCn55UsUsjy57"
            target="_blank"
            className=" text-white   rounded-lg cursor-pointer transition duration-250 ease-out hover:ease-in"
          >
            <div className="px-[16px] py-[8px]">Apply ICO</div>
          </a>
          {/* <Link
            to="/stack"
            className="text-white rounded-lg cursor-pointer transition duration-250 ease-out hover:ease-in"
          >
            <div className="px-[16px] py-[8px]">Staking</div>
          </Link> */}
        </div>
        <div className="block md:hidden" onClick={handleBurger}>
          <GiHamburgerMenu color={"#fff"} size={30} />
        </div>
        {/* //Connect Wallet and Address  */}
        {isConnect && (
          <div className="hidden md:block group">
            <div className=" hidden md:flex gap-3 px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300 bg-gradient-to-r from-[#f4e9b0] to-[#cca143]  hover:shadow-xl hover:shadow-[#060b27]/20">
              <span>
                <IoWallet size={20} />
              </span>{" "}
              <span>{spliceString(props.address).toUpperCase()}</span>
            </div>
            <ul class="absolute hidden text-gray-700 pt-1  group-hover:block shadow-xl bg-[#fff] shadow-[#060b27]/20 rounded-md hover: ">
              <li
                class=" flex text-black gap-3 px-[16px] py-[8px] cursor-pointer hover:bg-[#f7f9fc] rounded-md"
                onClick={handleWalletDisconnect}
              >
                <span>
                  <IoExitOutline size={20} />
                </span>
                <span>Disconnect Wallet</span>
              </li>
            </ul>
          </div>
        )}
        {!isConnect && (
          <div
            onClick={handleOpenConnectModal}
            className="hidden md:block px-[16px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] py-[8px] bg-[#060b27] rounded-md text-black font-bold cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
          >
            Connect Wallet
          </div>
        )}
      </div>{" "}
      {/* Wallect Connect option */}
      {isConnetModalOpen && (
        <div className="block fixed overflow-hidden top-0 z-[1000] h-full w-full">
          <div className="flex justify-center flex-col h-full items-center bg-[#00000052]    ">
            <div className=" rounded-lg  max-h-[500px] max-w-[500px] w-full  ">
              <div
                className="mb-[20px] cursor-pointer"
                onClick={handleCloseConnectModal}
              >
                <IoCloseCircleSharp
                  size={40}
                  color={"#060b27"}
                  className="ml-auto hover:opacity-90"
                />
              </div>
              <div className="shadow-xl shadow-[#060b27]/20">
                {" "}
                <div className="rounded-tl-lg rounded-tr-lg  py-[25px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] text-black font-bold">
                  <h3 className=" text-black text-2xl m-0">Connect Wallet</h3>
                </div>
                <div className="">
                  <div className=" flex flex-col gap-5 font-semibold text-xl  bg-[#fff] p-[16px] pb-[32px]  rounded-lg">
                    <div
                      onClick={async () => {
                        UpdateConnectLoading(true);
                        await ConnectMetamask().then(async () => {
                          setisConnect(true);
                          handleCloseConnectModal();
                          UpdateWalletBalance();
                        });
                      }}
                      className="  flex p-3 items-center border border-[#e8ecf4] cursor-pointer hover:shadow-xl hover:shadow-[#060b27]/10 rounded ease-out duration-300"
                    >
                      <span>
                        <img
                          src={process.env.PUBLIC_URL + "/assets/metamask.svg"}
                          alt="metamask"
                          width="32px"
                        ></img>
                      </span>
                      <span className="ml-3">Metamask</span>
                    </div>
                    <div
                      onClick={() => {
                        UpdateConnectLoading(true);
                        ConnectWeb3Wallet().then(async () => {
                          setisConnect(true);
                          console.log(store.getState().ConnectWallet);
                          handleCloseConnectModal();
                          UpdateWalletBalance();
                        });
                      }}
                      className="flex p-3 items-center border border-[#e8ecf4] cursor-pointer hover:shadow-xl hover:shadow-[#060b27]/10 rounded ease-out duration-300"
                    >
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/walletconnect.png"
                          }
                          alt="wallet_Connect"
                          width="32px"
                        ></img>
                      </span>
                      <span className="ml-3">Wallet Connect</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Mobile View */}
      {isBurger && (
        <div className="fixed z-[10000] bg-black py-[10px] w-full">
          <div className="flex flex-col items-center w-full  drop-shadow-xl">
            <Link
              to="/"
              onClick={() => setisBurger(false)}
              className="hover:bg-[#f0f7ff] w-full  cursor-pointer transition duration-250 ease-out hover:ease-in"
            >
              <div className="px-[16px] py-[16px] text-white ">Dashboard</div>
            </Link>
            <Link
              to="/create"
              onClick={() => setisBurger(false)}
              className="hover:bg-[#f0f7ff] w-full cursor-pointer transition duration-250 ease-out hover:ease-in"
            >
              <div className="px-[16px] py-[16px] text-white">Apply IDO</div>
            </Link>{" "}
            <div className="hover:bg-[#f0f7ff] w-full  cursor-pointer transition duration-250 ease-out hover:ease-in">
              {!isConnect && (
                <div
                  onClick={() => {
                    setisBurger(false);
                    handleOpenConnectModal();
                  }}
                  className="  px-[16px] py-[8px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
                >
                  Connect Wallet
                </div>
              )}
            </div>
            <div className="hover:bg-[#f0f7ff] w-full border-b-2 rounded-lg cursor-pointer transition duration-250 ease-out hover:ease-in">
              {" "}
              {isConnect && (
                <div className="group ">
                  <div className="flex gap-3 px-[16px] py-[8px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20">
                    <span>
                      <IoWallet size={20} />
                    </span>{" "}
                    <span>
                      {spliceString(
                        store.getState().ConnectWallet.address
                      ).toUpperCase()}
                    </span>
                  </div>
                  <ul class="absolute hidden text-gray-700 pt-1  group-hover:block shadow-xl bg-[#fff] shadow-[#060b27]/20 rounded-md hover: ">
                    <li
                      class=" flex text-black gap-3 px-[16px] py-[8px] cursor-pointer hover:bg-[#f7f9fc] rounded-md"
                      onClick={handleWalletDisconnect}
                    >
                      <span>
                        <IoExitOutline size={20} />
                      </span>
                      <span>Disconnect Wallet</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps, null)(Header);
