import React from "react";

function Footer() {
  return (
    <>
      {" "}
      <div class="w-full bg-black text-center text-white text-sm py-[48px] border-t border-[#d9b865] ">
        <div>© 2022 Project Psalms</div>
      </div>
    </>
  );
}

export default Footer;
