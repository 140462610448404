import Web3 from "web3";
import { FactotyABI } from "../Config/ABI/FactotyABI";
import { FactotyContract } from "../Config/Contract/Contract";
import { web3_ } from "../Services";
//Get Contract
// export const FactoryHelperContract = () => {
//   return new web3_.eth.Contract(FactotyABI, FactotyContract);
// };

// export const FactoryGetAllIntractionDetails = async () => {
//   let web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
//   let details = await new web3.eth.Contract(FactotyABI, FactotyContract).methods
//     .getAllIntractionDetails()
//     .call();
//   console.log(details);
//   return details;
// };
export async function FactoryGetIDOCounter() {
  return await new web3_.eth.Contract(FactotyABI, FactotyContract).methods
    .idoCounter()
    .call();
}

export const FactoryGetDataByAddress = async (address) => {
  return await new web3_.eth.Contract(FactotyABI, FactotyContract).methods
    .getDataByAddress(address)
    .call();
};
// export const FactoryGetLastIDOStatusEnded = async () => {
//   let details = await FactoryHelperContract()
//     .methods.getLastIDOStatusEnded()
//     .call();
//   return details;
// };
export const FactoryGetAllContract = async () => {
  let web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
  let details = await new web3.eth.Contract(FactotyABI, FactotyContract).methods
    .getIntractionAddrs()
    .call();
  console.log(details);
  return details;
};

export const FactoryGetIndexValue = async (Array, address) => {
  let web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
  let details = await new web3.eth.Contract(FactotyABI, FactotyContract).methods
    .getIndexValue(Array, address)
    .call();
  console.log(details);
  return details;
};
export const FactoryHelperContract = () => {
  return new web3_.eth.Contract(FactotyABI, FactotyContract);
};

export const FactoryGetAllIntractionDetails = async () => {
  let details = await FactoryHelperContract()
    .methods.getAllIntractionDetails()
    .call();
  console.log(details);
  return details;
};

export const FactoryGetLastIDOStatusEnded = async () => {
  let details = await FactoryHelperContract()
    .methods.getLastIDOStatusEnded()
    .call();
  return details;
};
