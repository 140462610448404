import { StakingABI } from "../Config/ABI/StackingABI";
import { TokenCreationABI } from "../Config/ABI/TokenCreationABI";
import { StakingContract } from "../Config/Contract/Contract";
import { store } from "../Redux/store";
import { web3_ } from "../Services";
import { FactoryGetIDOCounter } from "./FactoryHelper";

export const StackingHelperContract = () => {
  return new web3_.eth.Contract(StakingABI, StakingContract);
};
export function StackingPoolStake(StakingContract, AmountData, stackDuration) {
  return new web3_.eth.Contract(StakingABI, StakingContract).methods
    .poolStake(web3_.utils.toWei(AmountData.toString(), "ether"), stackDuration)
    .send({
      from: store.getState().ConnectWallet.address,
    });
}
export const StackingPoolTotalStake = async (address) => {
  return new web3_.eth.Contract(StakingABI, address).methods
    .totalStaked()
    .call();
};
export const StackingIDOAddress = async (address) => {
  return new web3_.eth.Contract(StakingABI, address).methods
    .IDOContractAddress()
    .call();
};
export const StackingPooluserTotalAllocation = async (counter) => {
  return new web3_.eth.Contract(StakingABI, StakingContract).methods
    .userTotalAllocation(counter, store.getState().ConnectWallet.address)
    .call();
};

export const StackingPoolUnstake = async (address) => {
  return new web3_.eth.Contract(StakingABI, address).methods
    .unstake()
    .send({ from: store.getState().ConnectWallet.address });
};

export const StackingIsAlreadyStaked = async (address, walletAddress) => {
  return new web3_.eth.Contract(StakingABI, address).methods
    .isAlreadyStaked(walletAddress)
    .call();
};
export const StackingPoolstakeCounter = async (counter, address) => {
  return new web3_.eth.Contract(StakingABI, StakingContract).methods
    .stakeCounter(counter, address)
    .call();
};

export const StackingPoolstakersDataset = async (
  idoCounter,
  stackCounter,
  address
) => {
  return new web3_.eth.Contract(StakingABI, StakingContract).methods
    .stakersDataset(idoCounter, stackCounter, address)
    .call();
};

export const StackingICOTokenSymbol = async (address) => {
  return new web3_.eth.Contract(TokenCreationABI, address).methods
    .symbol()
    .call();
};
export const StackingICOTokenDecimal = async (address) => {
  return new web3_.eth.Contract(TokenCreationABI, address).methods
    .decimals()
    .call();
};
export const StackingStakersDataset = async (address, walletAddress) => {
  return new web3_.eth.Contract(StakingABI, address).methods
    .stakersDataset(walletAddress)
    .call();
};
