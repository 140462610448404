import { PayoutTokenABI } from "../Config/ABI/PayoutTokenABI";
import {
  PayoutTokenContract,
  StakingContract,
} from "../Config/Contract/Contract";
import { store } from "../Redux/store";
import { web3_ } from "../Services";

export function PayoutTokenApprove(
  ICOTokenContractAddress,
  AmountData,
  stackAddress
) {
  return new web3_.eth.Contract(PayoutTokenABI, ICOTokenContractAddress).methods
    .approve(stackAddress, web3_.utils.toWei(AmountData.toString(), "ether"))
    .send({
      from: store.getState().ConnectWallet.address,
    });
}

export function PayoutTokenBalanceForSpecificUser(address) {
  return new web3_.eth.Contract(PayoutTokenABI, address).methods
    .balanceOf(store.getState().ConnectWallet.address)
    .call();
}
