import React, { useState, useEffect } from "react";
import { Form, Input, Button, InputNumber, DatePicker } from "antd";
import Swal from "sweetalert2";
import { UpdateConnectModal } from "../Redux/Action";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import moment from "moment";

function FormCreatePage(props) {
  const [Startdate, setStartdate] = useState(moment().format("YYYY-MM-DD"));
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };
  const onFinish = (values) => {
    console.log(props.isConnect);
    if (!props.isConnect) {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      }).then(() => {
        UpdateConnectModal(true);
      });
    } else {
      let fullname = values.FullName;
      let email = values.Email;
      let tokenName = values.Name;
      let tokenSymbol = values.Symbol;
      let rate = parseFloat(values.Rate).toFixed(3);
      let softCap = parseFloat(values.Soft_Cap).toFixed(3);
      let hardCap = parseFloat(values.Hard_Cap).toFixed(3);
      let startDate = parseInt(new Date(values.startDate).getTime() / 1000);
      let endDate = parseInt(new Date(values.endDate).getTime() / 1000);
      setStartdate(startDate);
      console.log();

      if (softCap < hardCap && startDate < endDate) {
        fetch("http://127.0.0.1:5000/ido/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullname: fullname,
            email: email,
            tokenName: tokenName,
            tokenSymbol: tokenSymbol,
            presaleRate: rate,
            softCap: softCap,
            hardCap: hardCap,
            startDate: startDate,
            endDate: endDate,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              Swal.fire({
                icon: "warning",
                title: "IDO Already Have ",
              });
            }
            if (data.result) {
              Swal.fire({
                icon: "success",
                title: "IDO Added Successfully",
              });
            }
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Soft Cap is less than to Hard Cap",
        });
      }
    }
    console.log("Success:", values);
  };

  return (
    <>
      {" "}
      <Form
        name="time_related_controls"
        initialValues={{
          remember: true,
        }}
        layout={"vertical"}
        onFinish={(values) => onFinish(values)}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {InputData.map((item, key) => (
          <Form.Item
            label={item.title}
            name={item.placeholder}
            key={key}
            rules={[
              {
                required: true,
                message: `Please input your ${item.placeholder}!`,
              },
              {
                pattern: item.pattern,
                message: item.message,
              },
            ]}
          >
            <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
              <div className="w-full border-none text-left">
                {item.type === "text" ? (
                  <Input className=" w-full bg-[transparent]  outline-0" />
                ) : (
                  <InputNumber
                    className=" w-full bg-[transparent]  outline-0"
                    min="0"
                    step="0.0001"
                    stringMode
                  />
                )}
              </div>
            </div>
          </Form.Item>
        ))}
        {/* <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
              <div className="w-full border-none "> */}
        <Form.Item name="startDate" label="Start Date" {...config}>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            className="w-full hover:outline-none"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />
        </Form.Item>
        {/* </div> */}
        {/* </div> */}
        <Form.Item name="endDate" label="End Date" {...config}>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            className="w-full hover:outline-none"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  isConnect: state.ConnectWallet.isConnect,
});
export default connect(mapStateToProps, null)(FormCreatePage);

const InputData = [
  {
    title: "Full Name",
    placeholder: "FullName",
    type: "text",
  },
  {
    title: "Email",
    placeholder: "Email",
    type: "text",
    pattern: new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    ),
    message: "Enter valid email",
  },

  {
    title: "Token Name",
    placeholder: "Name",
    type: "text",
  },
  {
    title: "Token Symbol",
    placeholder: "Symbol",
    type: "text",
    pattern: new RegExp(/^.{3,4}$/),
    message: "Symbol length must be between 3 and 4 characters",
  },
  {
    title: "Presale Rate",
    placeholder: "Rate",
    type: "number",
  },
  {
    title: "Soft Cap",
    placeholder: "Soft_Cap",
    type: "number",
  },
  {
    title: "Hard Cap",
    placeholder: "Hard_Cap",
    type: "number",
  },
];
