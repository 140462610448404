import { BUSDTokenABI, TokenABI } from "../Config/ABI/TokenABI";
import { store } from "../Redux/store";
import { web3_ } from "../Services";

//Get Contract
export const BUSDHelperContract = () => {
  // return new web3_.eth.Contract(BUSDTokenABI, BUSDToken);
};

// Get Balance of user
export const GetTokenBalance = async (address, tokenAddress) => {
  let balance = new web3_.eth.Contract(TokenABI, tokenAddress).methods
    .balanceOf(address)
    .call();
  return balance;
};
export const ApproveTokenHelper = async (
  IdoAddress,
  tokenAddress,
  amount,
  WalletAddress
) => {
  await new web3_.eth.Contract(TokenABI, tokenAddress).methods
    .approve(IdoAddress, amount)
    .send({ from: WalletAddress });
};
