import React, { useState, useEffect } from "react";
import { VscGlobe } from "react-icons/vsc";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  ICOHelperContract,
  ICOHelperPayoutContractTokenSymbol,
} from "../Helpers/ICOHelper";
import { FiArrowRight } from "react-icons/fi";
import { DivideBy18 } from "../Helpers/utils";
import { store } from "../Redux/store";
import moment from "moment";
import { Form, InputNumber, Button } from "antd";
import {
  FactoryGetAllContract,
  FactoryGetDataByAddress,
  FactoryGetIDOCounter,
  FactoryGetIndexValue,
} from "../Helpers/FactoryHelper";
import Swal from "sweetalert2";
import Loading from "../Components/Loading/Loading";
import { web3_ } from "../Services";
import {
  ApproveBUSDHelper,
  ApproveTokenHelper,
  GetBUSDBalanceHelper,
  GetTokenBalance,
} from "../Helpers/BUSDToken";
import CounterComponent from "../Components/CounterComponent";
import UnstackModal from "../Components/Modal/UnstackModal";
import {
  StackingPoolTotalStake,
  StackingPooluserTotalAllocation,
} from "../Helpers/StackingHelper";
import UnstackICOModal from "../Components/Modal/UnstackICOModal";
import { PayoutTokenABI } from "../Config/ABI/PayoutTokenABI";
import { FormControlUnstyled } from "@mui/base";
function IDOPage(props) {
  const { address } = useParams();
  const [ICOContract, setICOContract] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isApproveDone, setisApproveDone] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [TotalStake, setTotalStake] = useState(0);
  const [ApproveAmount, setApproveAmount] = useState(0);
  const [Counter, setCounter] = useState("");
  const [payoutContractSymbol, setPayoutContractAddress] = useState("BUSD");
  const [ICOTokenContractSymbol, setICOTokenContractSymbol] = useState("PTT");
  const [SocialMediaData, setSocialMediaData] = useState(null);
  const [SellTrackDataset, SetSellTrackDataset] = useState(null);
  const [ICOData, setICOData] = useState({
    PresaleRate: 0,
    softCap: 0,
    hardCap: 0,
    startTime: 0,
    endTime: 0,
    rasiedAmount: 0,
    tokenName: "Name",
    tokenSymbol: "NAM",
    description: "",
    fbLink: "",
    twLink: "",
    weblink: "",
    iconLink: "",
    payoutContractAddress: "",
    stakingContractAddress: "",
    idoTokenContractAddress: "",
    minimumInvestment: 0,
    maximumInvestment: 0,
    isSaleEnded: false,
    ClaimableToken: 0,
    isStakeEnable: false,
  });

  console.log(props);

  const handleClaimToken = async () => {
    let isSaleEnded = await ICOContract.methods.isSaleEnded().call();
    let getClaimableToken = await ICOContract.methods
      .getClaimableToken(props.WalletAddress)
      .call();

    console.log(
      isSaleEnded,
      ICOData.idoTokenContractAddress,
      getClaimableToken
    );

    // await ICOContract.methods.claimToken().send({
    //   from: props.WalletAddress,
    // });
    // setisLoading(true);
    // setisLoading(false);
    if (
      ICOData.idoTokenContractAddress !==
      "0x0000000000000000000000000000000000000000"
    ) {
      if (isSaleEnded) {
        if (getClaimableToken > 0) {
          let SellTrackDataset = await ICOContract.methods
            .SellTrackDataset(props.WalletAddress)
            .call();
          console.log(SellTrackDataset);
          if (!SellTrackDataset.isIDOTokenReceived) {
            let BalanceOf = await new web3_.eth.Contract(
              PayoutTokenABI,
              ICOData.idoTokenContractAddress
            ).methods
              .balanceOf(address)
              .call();
            console.log(BalanceOf);
            if (
              parseFloat(ICOData.ClaimableToken / Math.pow(10, 18)).toFixed(
                3
              ) <=
              parseFloat(BalanceOf).toFixed(3) / Math.pow(10, 18)
            ) {
              await ICOContract.methods
                .claimToken()
                .send({ from: props.WalletAddress })
                .then((result) => {
                  setisLoading(false);
                  console.log(result);
                });
            } else {
              setisLoading(false);
              Swal.fire("Warning!", "ICO don't have enough supply", "warning");
            }
          } else {
            setisLoading(false);
            Swal.fire("Warning!", "User Already Got thier Token", "warning");
          }
        } else {
          setisLoading(false);
          Swal.fire(
            "Warning!",
            "User does not have amount to calim.",
            "warning"
          );
        }
      } else {
        setisLoading(false);
        Swal.fire("Warning!", "Sale is not end", "warning");
      }
    } else {
      setisLoading(false);
      Swal.fire("Warning!", "Token Address is not set", "warning");
    }
  };

  useEffect(async () => {
    if (props.isConnect) {
      console.log(address);
      let ICOcontract = await ICOHelperContract(address);
      console.log(ICOcontract);
      setICOContract(ICOcontract);
      // UpdateConnectLoading(true);
      // Get Basic Detils of IDO

      await ICOcontract.methods
        .showAllTrade()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            investeUserAddress: result[0],
          }));
          // setInvesteUser(result[1]);
        });

      await ICOcontract.methods
        .SellTrackDataset(props.WalletAddress)
        .call()
        .then((result) => {
          console.log(result);
          SetSellTrackDataset(result);
        });

      await ICOcontract.methods
        .getClaimableToken(props.WalletAddress)
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            ClaimableToken: result,
          }));
        });
      await ICOcontract.methods
        .getSocialMediaData()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            tokenName: result[0],
            tokenSymbol: result[1],
            iconLink: result[2],
            fbLink: result[3],
            twLink: result[4],
            weblink: result[5],
            description: result[6],
          }));
        });

      await ICOcontract.methods
        .getStakingData()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            isStakeEnable: result[0],
            stakingContractAddress: result[1],
          }));
        });
      await ICOcontract.methods
        .owner()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            ownerAddress: result,
          }));
        });
      await ICOcontract.methods
        .getTokenomics()
        .call()
        .then(async (result) => {
          // UpdateConnectLoading(false);
          setPayoutContractAddress(
            await ICOHelperPayoutContractTokenSymbol(result[10])
          );
          setICOTokenContractSymbol(
            await ICOHelperPayoutContractTokenSymbol(result[11])
          );
          setICOData((value) => ({
            ...value,
            PresaleRate: result[0],
            softCap: result[1],
            hardCap: result[2],
            startTime: result[3],
            endTime: result[4],
            minimumInvestment: result[5],
            maximumInvestment: result[6],
            payoutContractAddress: result[10],
            idoTokenContractAddress: result[11],
            rasiedAmount: result[8],
          }));
        });
      ICOcontract.methods
        .isSaleEnded()
        .call()
        .then((result) =>
          setICOData((value) => ({
            ...value,
            isSaleEnded: result,
          }))
        );
    }
  }, [props.isConnect, props.WalletAddress]);

  async function handleUpdateValues() {
    await ICOContract.methods
      .getTokenomics()
      .call()
      .then((result) => {
        setICOData((value) => ({
          ...value,
          PresaleRate: result[0],
          softCap: result[1],
          hardCap: result[2],
          startTime: result[3],
          endTime: result[4],
          rasiedAmount: result[8],
          idoTokenContractAddress: result[11],
        }));
      });
    await ICOContract.methods
      .getClaimableToken(props.WalletAddress)
      .call()
      .then((result) =>
        setICOData((value) => ({
          ...value,
          ClaimableToken: result,
        }))
      );

    // Get User Allocation
    setisLoading(false);
  }
  const onFinish = async (values) => {
    let amount = parseFloat(values.Amount);
    console.log(amount, values.Amount);
    let AmountInWei = web3_.utils.toWei(amount.toString(), "ether");
    let payoutTokenBalance = parseInt(
      await GetTokenBalance(props.WalletAddress, ICOData.payoutContractAddress)
    );
    if (!props.isConnect) {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      });
    } else {
      if (amount > 0) {
        if (
          parseFloat(SellTrackDataset.amount) >=
          parseFloat(ICOData.maximumInvestment)
        ) {
          console.log(
            parseFloat(SellTrackDataset.amount),
            parseFloat(ICOData.maximumInvestment),
            amount
          );
          Swal.fire({
            icon: "warning",
            title: "User have reached its maximum investment",
          });
        } else {
          if (payoutTokenBalance >= amount) {
            if (
              parseFloat(ICOData.minimumInvestment) / Math.pow(10, 18) <=
                amount &&
              amount <= parseFloat(ICOData.maximumInvestment) / Math.pow(10, 18)
            ) {
              if (
                amount <=
                parseFloat(ICOData.hardCap - ICOData.rasiedAmount) /
                  Math.pow(10, 18)
              ) {
                setisLoading(true);

                ApproveTokenHelper(
                  address,
                  ICOData.payoutContractAddress,
                  AmountInWei,
                  props.WalletAddress
                )
                  .then(() => {
                    setisLoading(false);
                    setisApproveDone(true);
                    setApproveAmount(parseFloat(amount).toFixed(3));
                    Swal.fire({
                      icon: "success",
                      title: "Trasaction Successfull",
                    });
                  })
                  .catch((err) => {
                    setisLoading(false);
                    setisApproveDone(false);
                    console.log(err);
                    Swal.fire({
                      icon: "failed",
                      title: "Trasaction Failed",
                    });
                  });
              } else {
                Swal.fire({
                  icon: "warning",
                  title:
                    "Enter amount less or equal to" +
                    parseFloat(ICOData.hardCap - ICOData.rasiedAmount).toFixed(
                      2
                    ) /
                      Math.pow(10, 18),
                });
              }
            } else {
              Swal.fire({
                icon: "warning",
                title: "Please enter amount between minimum and maximum",
              });
            }
          } else {
            setisLoading(false);
            setisApproveDone(false);
            Swal.fire({
              icon: "failed",
              title: "You dont have enough BUSD",
            });
          }
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Please enter valid amount",
        });
      }
    }
  };
  async function handleUnstake() {
    setisOpen(true);
  }
  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <main className="bg-black">
        <div>
          <div className="hidden md:block ">
            <img
              src={process.env.PUBLIC_URL + "/assets/cardbg.svg"}
              width="40%"
              // iconLink
              alt=""
            ></img>
          </div>
          <div className="mt-0 md:-mt-[200px] relative z-10 max-w-[1000px] mx-auto pb-[120px]">
            <div className="flex flex-col md:flex-row gap-[24px]">
              <div className="flex-1">
                {/* <!-- IDO details --> */}

                <div className="mb-[23px] max-w-[800px] mx-auto min-h-[400px] bg-white  border border-[#e8ecf4] rounded-lg  px-[40px] drop-shadow-xl ">
                  <div className="py-[24px]">
                    <div className="flex items-center gap-5">
                      <div className="rounded-full w-[60px] p-1 bg-[#000] border border-black  h-[60px]  flex items-center">
                        <img
                          className="rounded-full mr-[8px] "
                          src={ICOData.iconLink}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://psalam-ido-launchpad.vercel.app/assets/logo.png";
                          }}
                        ></img>
                      </div>
                      <div className="my-[16px] tracking-tight text-[34px] text-black  font-bold font-show-one">
                        {ICOData.tokenName}
                      </div>
                    </div>
                    <div>
                      <p className="text-left text-black font-bold">
                        {ICOData.description}
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-3 text-left text-black font-bold">
                      {/* {ICOData.weblink === " " ? ( */}
                      <div className="text-left py-[16px] flex items-center">
                        {ICOData.weblink !== "" && (
                          <a
                            className=" text-black-500 px-[10px]"
                            href={ICOData.weblink}
                            target="_blank"
                          >
                            <span className="flex">
                              <VscGlobe size={20} color="#c4922f" />
                            </span>
                          </a>
                        )}
                      </div>
                      {/* ) : null} */}
                      {/* {ICOData.fbLink === " " ? ( */}
                      <div className="text-left py-[16px] flex items-center">
                        {ICOData.fbLink !== "" && (
                          <a
                            className="  px-[10px]"
                            href={ICOData.fbLink}
                            target="_blank"
                          >
                            <span className="flex">
                              <BsFacebook size={20} color="#c4922f" />
                            </span>
                          </a>
                        )}
                      </div>
                      {/* ) : null} */}

                      {/* {ICOData.twLink === " " ? ( */}
                      <div className="text-left py-[16px] flex items-center">
                        {ICOData.twLink !== "" && (
                          <a
                            className=" text-blue-500 px-[10px]"
                            href={ICOData.twLink}
                            target="_blank"
                          >
                            {" "}
                            <span className="flex">
                              <BsTwitter size={20} color="#c4922f" />
                            </span>
                          </a>
                        )}
                      </div>
                      {/* ) : null} */}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4] text-blue-500">
                    <div className="text-black font-bold">Address</div>
                    <a
                      className="text-black font-bold text-[12px] md:text-md"
                      href={`https://testnet.bscscan.com/address/` + address}
                      target="_blank"
                    >
                      {address}
                    </a>
                  </div>

                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">ICO Name</div>
                    <div className="text-black font-bold">
                      {" "}
                      {ICOData.tokenName}{" "}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">ICO Symbol </div>
                    <div className="text-black font-bold">
                      {" "}
                      {ICOData.tokenSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Token Symbol </div>
                    <div className="text-black font-bold">
                      {ICOTokenContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Presale Rate</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.PresaleRate)} {ICOTokenContractSymbol}
                      /{payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Hard Cap</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.hardCap)} {payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Soft Cap</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.softCap)} {payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Min Invest</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.minimumInvestment)}{" "}
                      {payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Max Invest</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.maximumInvestment)}{" "}
                      {payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Raised Amount</div>
                    <div className="text-black font-bold">
                      {DivideBy18(ICOData.rasiedAmount)} {payoutContractSymbol}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">Start Date</div>
                    <div className="text-black font-bold">
                      {" "}
                      {moment(parseInt(ICOData.startTime) * 1000).format(
                        "MMM Do YYYY"
                      )}
                    </div>
                  </div>
                  <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                    <div className="text-black font-bold">End Date</div>
                    <div className="text-black font-bold">
                      {" "}
                      {moment(parseInt(ICOData.endTime) * 1000).format(
                        "MMM Do YYYY"
                      )}
                    </div>
                  </div>

                  <div className="py-[24px] flex justify-between border-b items-center border-[#e8ecf4]">
                    <div className="text-black font-bold">Progress</div>
                    <div className="w-[50%] h-full">
                      <div
                        className={`rounded-full h-[12px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] w-[${
                          (ICOData.rasiedAmount / ICOData.hardCap) * 100
                        }%] `}
                      ></div>
                      <div className="flex text-black font-bold justify-between">
                        <span>0%</span>
                        <span>
                          {parseInt(
                            (ICOData.rasiedAmount / ICOData.hardCap) * 100
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {ICOData.startTime < new Date().getTime() / 1000 ? (
                  <div className="flex-[0.5] rounded-lg ">
                    <div className="w-full bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] font-bold color-black border border-[#e8ecf4] rounded-md  px-[40px] drop-shadow-xl text-center py-[20px]">
                      <h3 className="font-bold mt-[20px] text-[24px] text-left">
                        COUNTDOWN
                      </h3>
                      <CounterComponent
                        endDate={ICOData.endTime}
                        isEnded={ICOData.isSaleEnded}
                      />
                      {!ICOData.isSaleEnded ? (
                        <>
                          <div className="">
                            <div className="flex justify-between  my-[30px] ">
                              <span className="text-black">Get Token</span>
                              <span className="text-black">
                                {parseFloat(
                                  ICOData.ClaimableToken / Math.pow(10, 18)
                                ).toFixed(3)}
                                &nbsp;
                                {ICOTokenContractSymbol.toUpperCase()}
                              </span>
                            </div>
                          </div>
                          <Form
                            name="time_related_controls"
                            initialValues={{
                              remember: true,
                            }}
                            layout={"vertical"}
                            onFinish={(values) => onFinish(values)}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            <div className=" flex flex-col justify-center">
                              <Form.Item
                                label={"Amount"}
                                name={"Amount"}
                                rules={[
                                  {
                                    required: true,
                                    message: `Enter Amount`,
                                  },
                                ]}
                              >
                                <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
                                  <div className="w-full border-none text-left">
                                    {" "}
                                    <InputNumber
                                      className=" w-full bg-[transparent]  outline-0"
                                      min="0"
                                      step="0.001"
                                      placeholder={ApproveAmount}
                                      id="Approve_amount"
                                      disabled={isApproveDone}
                                    />
                                  </div>
                                </div>
                              </Form.Item>

                              <Form.Item>
                                <Button
                                  type="primary"
                                  style={{
                                    padding: "4px 15px",
                                  }}
                                  htmlType={!isApproveDone ? "submit" : null}
                                  onClick={
                                    isApproveDone
                                      ? async () => {
                                          console.log(
                                            web3_.utils.toWei(
                                              ApproveAmount,
                                              "ether"
                                            ),
                                            "fsdfgshdgfhh"
                                          );
                                          setisLoading(true);
                                          await ICOContract.methods
                                            .saleIDOToken(
                                              web3_.utils.toWei(
                                                ApproveAmount,
                                                "ether"
                                              )
                                            )
                                            .send({
                                              from: props.WalletAddress,
                                            })
                                            .then(() => {
                                              setisLoading(false);
                                              setisApproveDone(false);
                                              handleUpdateValues();
                                              setApproveAmount(0);
                                              Swal.fire({
                                                icon: "success",
                                                title: "Trasaction Successfull",
                                              });
                                            })
                                            .catch(() => {
                                              setisLoading(false);
                                              setisApproveDone(false);
                                              Swal.fire({
                                                icon: "failed",
                                                title: "Trasaction Failed",
                                              });
                                            });
                                          setisLoading(false);
                                        }
                                      : null
                                  }
                                >
                                  {isApproveDone ? "Buy Token" : "Approve"}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </>
                      ) : null}
                    </div>
                    {true && (
                      // ICOData.isSaleEnded
                      <div className="mt-5 w-full bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] border border-[#e8ecf4] rounded-md  px-[40px] drop-shadow-xl text-center py-[20px]">
                        <h3 className="font-bold mt-[20px] text-[24px] text-left">
                          Claim Token
                        </h3>
                        <Button
                          onClick={handleClaimToken}
                          type="primary"
                          style={{
                            padding: "4px 15px",
                          }}
                          // className="w-full px-[16px] mx-auto mt-[10px] py-[8px] bg-[#060b27] border border-white flex gap-[10px] items-center rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#fff]/20"
                        >
                          Claim Token
                        </Button>
                      </div>
                    )}{" "}
                    {/* // UNstake funcation */}
                    {/* //UNstake  */}
                    {/* {TotalStake > 0 && (
                    <div className="mt-5 w-full bg-white border border-[#e8ecf4] rounded-md  px-[40px] drop-shadow-xl text-center py-[20px]">
                      <h3 className="font-bold mt-[20px] text-[24px] text-left">
                        Unstake Token
                      </h3>
                      <div
                        onClick={handleUnstake}
                        className="w-full px-[16px] mx-auto mt-[10px] py-[8px] bg-[#060b27] border border-white flex gap-[10px] items-center rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#fff]/20"
                      >
                        <div>Unstack</div>
                        <div className="flex items-center hover:translate-x-16 transition ease-in-out delay-150">
                          <FiArrowRight />
                        </div>
                      </div>
                      <UnstackICOModal
                        modalOpen={isOpen}
                        setisOpen={setisOpen}
                        ICOaddress={address}
                      />
                    </div>
                  )} */}
                  </div>
                ) : (
                  <div className="flex-[0.5] rounded-lg ">
                    <div className="w-full bg-white border border-[#e8ecf4] rounded-md  px-[40px] drop-shadow-xl text-center py-[20px]">
                      <h3 className="font-bold mt-[20px] text-[24px] text-left">
                        ICO NOT START YET
                      </h3>
                    </div>
                  </div>
                )}

                {/* // Token Stack */}
                {ICOData.isStakeEnable ? (
                  <div className="flex-[0.5] rounded-lg mt-5">
                    <div className="w-full bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] font-bold color-black border border-[#e8ecf4] rounded-md  px-[40px] drop-shadow-xl text-center py-[20px]">
                      <h3 className="font-bold mt-[20px] text-[24px] text-left">
                        Staking
                      </h3>
                      {ICOData.isStakeEnable &&
                      ICOData.stakingContractAddress ===
                        "0x0000000000000000000000000000000000000000" ? (
                        <div>ICO Owner has not create Stake Contract</div>
                      ) : (
                        <Link to={"/stack/" + ICOData.stakingContractAddress}>
                          <Button type="primary">Stake</Button>
                        </Link>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
  WalletAddress: state.ConnectWallet.address,
  isLoadingEnable: state.UtilsReducers.isLoadingEnable,
});

export default connect(mapStateToProps, null)(IDOPage);
