import React, { useState, useEffect } from "react";
import { FiArrowRight } from "react-icons/fi";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../Components/Loading/Loading";
import UnstackModal from "../Components/Modal/UnstackModal";
import { StakingContract } from "../Config/Contract/Contract";
import { FactoryGetIDOCounter } from "../Helpers/FactoryHelper";
import { ICOHelperGetTokenomics } from "../Helpers/ICOHelper";
import {
  PayoutTokenApprove,
  PayoutTokenBalanceForSpecificUser,
} from "../Helpers/PayoutTokenHelper";
import {
  StackingICOTokenDecimal,
  StackingICOTokenSymbol,
  StackingIDOAddress,
  StackingIsAlreadyStaked,
  StackingPoolStake,
  StackingPoolTotalStake,
  StackingPoolUnstake,
  StackingPooluserTotalAllocation,
  StackingStakersDataset,
} from "../Helpers/StackingHelper";
import { DivideBy18 } from "../Helpers/utils";

const RewardPercentage = [2, 5, 20, 50];
function StackPage(props) {
  let { address } = useParams();
  console.log(address);
  const [isConnect, setisConnect] = useState(false);
  const [isTranasction, setisTranasction] = useState(false);
  const [isApproveDone, setisApproveDone] = useState(false);
  const [AmountData, setAmountData] = useState("");
  const [StackPoolDuration, setStackPoolDuration] = useState(1);
  const [TotalStake, setTotalStake] = useState(0);
  const [TotalBalance, setTotalBalance] = useState(0);
  const [TotalStakeBalance, setTotalStakeBalance] = useState(0);
  const [ICOTokenDecimal, setICOTokenDecimal] = useState(18);
  const [Counter, setCounter] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [payoutContractAddress, setPayoutContractAddress] = useState("");
  const [ICOTokenContractAddress, setICOTokenContractAddress] = useState("");
  const [ICOTokenSymbol, setICOTokenSymbol] = useState("");
  const [isAlreadyStacked, setIsAlreadyStacked] = useState(false);
  useEffect(() => {
    setisConnect(props._isConnect);
  }, [props._isConnect]);

  useEffect(async () => {
    console.log(props._isConnect);
    if (props._isConnect && props.Walletaddress) {
      setIsAlreadyStacked(
        await StackingIsAlreadyStaked(address, props.Walletaddress)
      );
      let icoAddress = await StackingIDOAddress(address);
      let tokonomics = await ICOHelperGetTokenomics(icoAddress);
      console.log(tokonomics);
      setPayoutContractAddress(tokonomics[10]);
      setTotalStake(await StackingStakersDataset(address, props.Walletaddress));
      setICOTokenSymbol(await StackingICOTokenSymbol(tokonomics[11]));
      setICOTokenDecimal(
        parseInt(await StackingICOTokenDecimal(tokonomics[11]))
      );
      setICOTokenContractAddress(tokonomics[11]);
      setTotalBalance(
        DivideBy18(await PayoutTokenBalanceForSpecificUser(tokonomics[10]))
      );
    } else {
      setTotalBalance(0);
      setTotalStakeBalance(0);
      setTotalStake(0);
      setisApproveDone(false);
      setAmountData("");
    }
    if (!props._isConnect) {
      setTotalBalance(0);
      setTotalStakeBalance(0);
      setTotalStake(0);
      setisApproveDone(false);
      setAmountData("");
      setCounter(0);
    }
  }, [props._isConnect, props.Walletaddress]);

  //handle submit function when user clicks on submit button in the form when handleSubmit is called.
  async function handleSubmit(e) {
    e.preventDefault();
    console.log(AmountData);

    if (parseFloat(AmountData) > 0) {
      if (AmountData !== "" && !isApproveDone) {
        if (parseFloat(TotalBalance) >= parseFloat(AmountData).toFixed(3)) {
          handleApprove();
        } else {
          Swal.fire("Amount", "You dont have enough fund", "warning");
        }
      } else {
        handleStack();
      }
    } else {
      Swal.fire("Invalid Input", "Please enter a valid amount", "warning");
    }
  }

  // handlesetStackPoolDuration function handles the change in the stack duration
  const handlesetStackPoolDuration = (index) => {
    setStackPoolDuration(index);
  };

  // handleApprove function handles the approval of the amount of tokens to be staked
  async function handleApprove() {
    setisTranasction(true);
    if (isConnect) {
      if (isAlreadyStacked) {
        Swal.fire("Warning", "You already have a Staked", "warning");
        setisTranasction(false);
        setisApproveDone(false);
      } else {
        console.log(ICOTokenContractAddress);
        PayoutTokenApprove(ICOTokenContractAddress, AmountData, address)
          .then((res) => {
            setisTranasction(false);
            setisApproveDone(true);
          })
          .catch(() => {
            setisTranasction(false);
            setisApproveDone(false);
          });
        // setisTranasction(false)
      }
    } else {
      Swal.fire("Connect", "Please Connect to wallet", "warning");
      setisTranasction(false);
      setisApproveDone(false);
    }
  }

  async function handleSetTotalStakeBalance() {
    setTotalStakeBalance(
      DivideBy18(await StackingPooluserTotalAllocation(Counter))
    );
  }
  // handleStack function handles the staking of the tokens
  function handleStack() {
    setisTranasction(true);
    if (isConnect) {
      StackingPoolStake(address, AmountData, StackPoolDuration)
        .then(async (res) => {
          setisTranasction(false);
          setisApproveDone(false);
          setAmountData("");
          setStackPoolDuration(1);
          setTotalStake(DivideBy18(await StackingPoolTotalStake(address)));
          // setTotalStakeBalance(
          //   DivideBy18(await StackingPooluserTotalAllocation(Counter))
          // );
          setIsAlreadyStacked(
            await StackingIsAlreadyStaked(address, props.Walletaddress)
          );
          setTotalStake(DivideBy18(await StackingPoolTotalStake(address)));
        })
        .catch(() => {
          setisTranasction(false);
        });
    } else {
      Swal.fire("Connect", "Please Connect to wallet", "warning");
      setisTranasction(false);
      setisApproveDone(false);
    }
  }

  // handleChange function handles the change in the amount of tokens to be staked
  function handleAmountChange(e) {
    setAmountData(e.target.value);
  }

  async function handleUnstake() {
    setisTranasction(true);
    if (isConnect) {
      StackingPoolUnstake(address)
        .then(async (res) => {
          setisTranasction(false);
          setisApproveDone(false);
          setAmountData("");
          setStackPoolDuration(1);
          setTotalStake(DivideBy18(await StackingPoolTotalStake(address)));
          setIsAlreadyStacked(
            await StackingIsAlreadyStaked(address, props.Walletaddress)
          );
        })
        .catch(() => {
          setisTranasction(false);
        });
    } else {
      Swal.fire("Connect", "Please Connect to wallet", "warning");
      setisTranasction(false);
      setisApproveDone(false);
    }
  }
  //if Transaction is in progress, show the loading component rather than the form will show.
  if (isTranasction) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <div
        className="   py-[80px]  "
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/assets/cardbg.svg)",
        }}
      >
        <div className="w-full md:mx-auto max-w-[1350px] flex flex-col-reverse md:flex-row h-full  ">
          <div className="flex-1 text-white mt-[24px] md:mt-0">
            <div className="text-left px-[24px] ">
              <h3 className="text-3xl font-bold mb-5  text-white">
                PROJECT PSALMS STAKING
              </h3>
              <div className="mb-5">
                <p className="mb-[10px]">
                  Only staked {ICOTokenSymbol} will be considered for all the
                  upcoming Sales in the Project Psalms Ecosystem.
                </p>
                <p className="mb-[10px]">
                  {" "}
                  Earn attractive APY on your staked Project Psalms tokens
                </p>
              </div>
              {/* //Table  */}
              <div className="mb-8 max-w-[400px] rounded text-center text-white border border-[#f7f9fc] shadow-xl shadow-[#fff]/10 ">
                <div className="flex py-3 font-bold items-center border-b px-[30px] text-[#060b27] bg-[#f7f9fc]   ">
                  <span className="flex-1 ">Staking pools (Duration)</span>
                  <span className="flex-1 ">Rewards (APY)</span>
                </div>
                {APYRewardsData.map((item, index) => (
                  <div className="flex p-2 border-b " key={index}>
                    <span className="flex-1 ">{item.duration}</span>
                    <span className="flex-1 ">{item.reward}%</span>
                  </div>
                ))}
              </div>
              {/* How to participate */}
              <h3 className="text-2xl font-bold mb-5 text-white">
                How to Participate :
              </h3>
              <ul className="list-disc">
                <li className="mb-[10px]">
                  Choose a duration and enter the amount you wish to stake and
                  take part in the upcoming sale.
                </li>
                <li className="mb-[10px]">
                  Click Approve to approve your {ICOTokenSymbol} to be used on
                  the platform and then Stake the desired number of{" "}
                  {ICOTokenSymbol}.
                </li>{" "}
                <li className="mb-[10px]">Thats it!!</li>
              </ul>
              <div className="my-[45px]">
                <strong>NOTE:</strong> If you unstake before the maturity period
                you will need to fee of 3% of your invested amount .
              </div>
            </div>
          </div>
          <div className="flex-1 px-[14px]">
            <div className="px-[30px] py-[40px] max-w-[500px] mx-auto bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] text-left rounded-md ">
              <h3 className="text-3xl text-[#060b27] font-bold mb-4">
                Choose a staking duration
              </h3>
              <div className="flex gap-5 flex-wrap justify-center">
                <div
                  onClick={() =>
                    !isApproveDone ? handlesetStackPoolDuration(1) : null
                  }
                  className={`py-[8px] px-[16px] ${
                    StackPoolDuration === 1
                      ? "bg-[#000] text-[#fff] border-2 border-[#060b27]"
                      : "bg-[#060b27] text-white"
                  }  rounded mb-5 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110`}
                >
                  15 Days
                </div>
                <div
                  onClick={() =>
                    !isApproveDone ? handlesetStackPoolDuration(2) : null
                  }
                  className={`py-[8px] px-[16px] ${
                    StackPoolDuration === 2
                      ? "bg-[#000] text-[#fff] border-2 border-[#060b27]"
                      : "bg-[#060b27] text-white"
                  } rounded mb-5 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110`}
                >
                  30 Days
                </div>
                <div
                  onClick={() =>
                    !isApproveDone ? handlesetStackPoolDuration(3) : null
                  }
                  className={`py-[8px] px-[16px] ${
                    StackPoolDuration === 3
                      ? "bg-[#000] text-[#fff] border-2 border-[#060b27]"
                      : "bg-[#060b27] text-white"
                  }  rounded mb-5 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110`}
                >
                  90 Days
                </div>
                <div
                  onClick={() =>
                    !isApproveDone ? handlesetStackPoolDuration(4) : null
                  }
                  className={`py-[8px] px-[16px] ${
                    StackPoolDuration === 4
                      ? "bg-[#000] text-[#fff] border-2 border-[#060b27]"
                      : "bg-[#060b27] text-white"
                  }  rounded mb-5 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110`}
                >
                  180 Days
                </div>
              </div>
              <p className="text-base mb-3 ">
                Please connect wallet or make sure you are in Binance Smart
                Chain mainnet to continue.
              </p>
              <div className="flex justify-between mb-[12px]">
                <span className="">APY(Annual Percentage Yield)</span>
                <span className="">
                  {RewardPercentage[StackPoolDuration - 1]} %
                </span>
              </div>
              {/* <div className="flex justify-between mb-[15px]">
                <span className="">Total ed </span>
                <span className="">{TotalStake} Pslams</span>
              </div> */}
              <div className="flex justify-between mb-[15px]">
                <span className="">User Stake</span>
                <span className="">
                  {TotalStake[1] / Math.pow(10, ICOTokenDecimal)}{" "}
                  {ICOTokenSymbol}
                </span>
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="flex rounded-md  border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
                    <div className="w-full border-none text-left">
                      <input
                        placeholder="Enter Amount"
                        className="w-full border-none outline-0 p-[8px]"
                        onChange={handleAmountChange}
                        value={AmountData}
                        disabled={isApproveDone}
                      ></input>
                    </div>
                  </div>
                  <div className="flex gap-5 flex-wrap">
                    {" "}
                    <button
                      type="submit"
                      className="w-full md:w-fit px-[16px] mx-auto mt-[10px] py-[8px] bg-[#000] border border-white flex gap-[10px] items-center rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#fff]/20"
                    >
                      <div>{isApproveDone ? "Stake" : "Approve"}</div>
                      <div className="flex items-center hover:translate-x-16 transition ease-in-out delay-150">
                        <FiArrowRight />
                      </div>
                    </button>
                    {isAlreadyStacked && (
                      <div
                        onClick={handleUnstake}
                        className="w-full md:w-fit px-[16px] mx-auto mt-[10px] py-[8px] bg-[#000] border border-white flex gap-[10px] items-center rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#fff]/20"
                      >
                        <div>Unstake</div>
                        <div className="flex items-center hover:translate-x-16 transition ease-in-out delay-150">
                          <FiArrowRight />
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <UnstackModal
          modalOpen={isOpen}
          setisOpen={setisOpen}
          setisTranasction={setisTranasction}
          handleSetTotalStakeBalance={handleSetTotalStakeBalance}
        />
      </div>
    );
  }
}
const APYRewardsData = [
  {
    duration: 15,
    reward: 2,
  },
  {
    duration: 30,
    reward: 5,
  },
  {
    duration: 90,
    reward: 20,
  },
  {
    duration: 180,
    reward: 50,
  },
];
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  _isConnect: state.ConnectWallet.isConnect,
  Walletaddress: state.ConnectWallet.address,
});
export default connect(mapStateToProps, null)(StackPage);
