import WalletConnectProvider from "@walletconnect/web3-provider";

//  Create WalletConnect Provider
export const provider = new WalletConnectProvider({
  infuraId: "9c48d1f781404552b1a017d597f6bee1",
  bridge: "https://bridge.walletconnect.org",
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  qrcode: true,
  qrcodeModalOptions: {
    mobileLinks: ["metamask", "trust"],
  },
  desktopLinks: ["encrypted ink"],
});
