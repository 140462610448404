import React, { useState, useEffect } from "react";
import PoolCard from "../Components/PoolCard";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  FactoryGetAllIntractionDetails,
  FactoryGetLastIDOStatusEnded,
} from "../Helpers/FactoryHelper";
import Loading from "../Components/Loading/Loading";

function HomePage(props) {
  const [isConnect, setisConnect] = useState(false);
  const [ICODetails, setICODetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lengthICO, setlengthICO] = useState(0);
  const [isLastICOEnded, setisLastICOEnded] = useState(false);
  useEffect(async () => {
    setisConnect(props._isConnect);
  }, [props._isConnect]);

  useEffect(async () => {
    setIsLoading(true);
    await FactoryGetAllIntractionDetails()
      .then((res) => {
        setlengthICO(res.length);
        setICODetails(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    FactoryGetLastIDOStatusEnded()
      .then((res) => {
        console.log(res);
        setisLastICOEnded(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  return (
    <div className="bg-black text-[#fff]">
      {/* //Heror page */}
      <div className="max-w-[1300px] mx-auto flex md:flex-row items-center gap-[50px] py-[48px] px-[20px] flex-col-reverse">
        <div className="flex-1 text-[#fff] text-left">
          <h1 className="my-[16px]  text-[#fff] tracking-tight md:text-[54px] text-[34px]  font-bold">
            First Decentralized IDO LaunchPad
          </h1>
          <p className="text-xl  max-w-[560px] mr-auto">
            Project Psalms will allow creators to launch their tokens most
            innovative crypto projects across all blockchains. Project Psalms
            will plug into multiple liquidity sources across chains, including
            the cross-chain liquidity ecosystem.
          </p>
          <div className="mt-[20px]">
            <a
              href="https://forms.gle/JsPAaCn55UsUsjy57"
              className="bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] block my-[16px] px-[20px] py-[10px]  w-fit  rounded-md text-black  font-bold cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
            >
              Apply ICO
            </a>
          </div>
        </div>
        <div className="flex-1 shadow-xl shadow-[#fbf4c0]/20 bg-transprent rounded-[20px]">
          <img
            className="rounded-[20px]"
            src={process.env.PUBLIC_URL + "/assets/pslam.png"}
          />
        </div>
      </div>
      {/* // Netwokr  */}
      <div className="my-[120px] bg-gradient-to-r from-[#c4922f] to-[#fbf4c0] h-[120px] flex items-center gap-[148px] justify-center">
        <div>
          <img src={process.env.PUBLIC_URL + "/assets/bnb.svg"}></img>
        </div>
      </div>
      {/* //IDO  */}
      <div>
        <div className="max-w-[1120px] mx-auto">
          <div className="pb-[40px]">
            <h3 className=" font-bold mb-[32px]  text-[#fff] md:text-[54px] text-[34px] ">
              ICO Cards
            </h3>

            {isLoading ? (
              <div className="relative h-full">
                <Loading />
              </div>
            ) : (
              <>
                {/* {lengthICO > 0 && !isLastICOEnded ? (
                  <div className="max-w-[300px] text-[#fff] my-[48px]">
                    <h3 className="text-left text-[22px] font-bold text-[#fff]">
                      Active ICO
                    </h3>
                    <PoolCard data={ICODetails[lengthICO - 1]} key={1} />
                  </div>
                ) : (
                  <h3 className="text-left  text-[#fff] lg:px-[120px] text-[22px] font-bold text-[#060b27]">
                    There is no active ICO
                  </h3>
                )} */}
                {/* <h3 className="text-left text-[22px] font-bold text-[#fff]">
                  {lengthICO - 1 >= 1 ? "Previous ICOs" : "Previous ICO"}
                </h3> */}
                <div
                  className="grid pt-[58px] gap-[24px] px-[10px]"
                  style={{
                    gridTemplateColumns: "repeat(auto-fill,minmax(250px,1fr))",
                  }}
                >
                  {/* {!isConnect && <div>Please connect your wallet</div>} */}
                  {
                    // lengthICO > 0 && !isLastICOEnded
                    //   ? ICODetails.map((data, item) => (
                    //       <>
                    //         {" "}
                    //         {lengthICO === item + 1 ? null : (
                    //           <PoolCard data={data} key={item} />
                    //         )}
                    //       </>
                    //     ))
                    //   :
                    ICODetails.map((data, item) => (
                      <PoolCard data={data} key={item} />
                    ))
                  }

                  {/* {ICODetails.map((data, item) => (
                    // <Card data={data} key={item} />
                    <>
                      {lengthICO === item + 1 ? null : (
                        <PoolCard data={data} key={item} />
                      )}
                    </>
                  ))} */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* News Letter  */}
      {/* <div
        className="h-[500px] w-full mt-[120px] flex items-center justify-center"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/assets/cardbg.svg)",
          opacity: "0.8",
        }}
      >
        <div className="text-[#fff] text-center opacity-1">
          <h3 className="my-[16px] tracking-tight leading-[64px] text-5xl font-semibold text-white">
            Get Alerts for New Pools
          </h3>
          <p className="text-base font-[400] mb-[20px]">
            Subscribe to get notified about new pools and other relevant events.
          </p>
        </div>
      </div> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  _isConnect: state.ConnectWallet.isConnect,
});
export default connect(mapStateToProps, null)(HomePage);
