import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FactoryGetAllContract } from "../../Helpers/FactoryHelper";
import {
  StackingPoolstakeCounter,
  StackingPoolstakersDataset,
  StackingPoolUnstake,
  StackingPooluserTotalAllocation,
} from "../../Helpers/StackingHelper";
import { connect } from "react-redux";
import { store } from "../../Redux/store";
import Swal from "sweetalert2";
import { UpdateConnectLoading, UpdateConnectModal } from "../../Redux/Action";
import { DivideBy18 } from "../../Helpers/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UnstackModal(props) {
  const [open, setOpen] = React.useState(true);
  const [Counter, SetCounter] = React.useState(false);
  const [stackCounter, setStackCounter] = React.useState([]);
  const handleOpen = () => props.setisOpen(true);
  const handleClose = () => props.setisOpen(false);
  useEffect(async () => {
    let counter, address;
    if (props._isConnect) {
      await FactoryGetAllContract().then((res) => {
        counter = res.length;
        address = res[counter - 1];
        SetCounter(res.length);
      });
      console.log(counter, address);
      await StackingPoolstakeCounter(
        counter,
        store.getState().ConnectWallet.address
      ).then(async (res) => {
        let Array = [];
        for (let index = 1; index <= res; index++) {
          Array.push(index);
        }
        setStackCounter(Array);
        console.log(Array);
      });
    }
  }, [props._isConnect]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unstake Ammount
          </Typography>
          {props.modalOpen ? (
            <>
              {" "}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {stackCounter.map((index, key) => (
                  <>
                    <div
                      key={key}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <strong>Unstake {index} :</strong>
                      </div>
                      <div
                        onClick={async () => {
                          props.setisTranasction(true);
                          await StackingPoolstakersDataset(
                            Counter,
                            parseInt(index),
                            store.getState().ConnectWallet.address
                          ).then((res) => {
                            if (res.stackAmount <= 0) {
                              props.setisTranasction(false);
                              props.setisOpen(false);
                              Swal.fire({
                                icon: "warning",
                                text: "You have already staked",
                              });
                            } else {
                              props.setisOpen(false);
                              props.setisTranasction(true);
                              StackingPoolUnstake(Counter, index)
                                .then(async () => {
                                  props.setisTranasction(false);
                                  props.handleSetTotalStakeBalance();
                                })
                                .catch(() => {
                                  props.setisTranasction(false);
                                });
                            }
                          });
                        }}
                        className="py-[8px] px-[16px] bg-[#060b27] text-white  rounded mb-5 cursor-pointer "
                      >
                        Click me{" "}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => ({
  address: state.ConnectWallet.address,
  _isConnect: state.ConnectWallet.isConnect,
});
export default connect(mapStateToProps, null)(UnstackModal);
